<template>
  <div>
    <TableComponent :fields="fields" :items="rooms" :busy="fetching">
      <template #cell(protocol)="slotProps">
        <span class="protocol-cell">
          {{ slotProps.data }}
        </span>
        <a href="javascript:void(0)">
        <span class="material-symbols-outlined"
              @click="copyProtocol(slotProps.data)">content_copy</span>
        </a>
      </template>
      <template #cell(channel)="slotProps">
        <BadgeComponent variant="success">{{ slotProps.data }}</BadgeComponent>
      </template>
      <template #cell(evaluation)="slotProps">
        <span v-if="slotProps.data" class="material-symbols-outlined">star</span>{{
          slotProps.data
        }}
      </template>
      <template #cell(actions)="slotProps">
        <router-link :to="`/chat/reports/history/${slotProps.item.id}`"><span
          class="material-symbols-outlined">search</span></router-link>
      </template>
    </TableComponent>
    <PaginationComponent v-model="currentPage" :total-pages="totalPages"
                         @change="fetch"></PaginationComponent>
  </div>
</template>

<script>
import moment from 'moment';
import TableComponent from '@/components/TableComponent.vue';
import BadgeComponent from '@/components/ui/BadgeComponent.vue';
import axios from '@/plugins/axios';
import PaginationComponent from '@/components/PaginationComponent.vue';

const webApi = axios();

export default {
  name: 'LiveReport',
  components: {
    PaginationComponent,
    TableComponent,
    BadgeComponent,
  },
  props: {
    filters: {
      type: Object,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {
      fetching: false,
      fields: [
        { key: 'creation_date', formatter: (value) => moment(value).format('DD/MM/YYYY HH:mm:ss') },
        'protocol',
        {
          key: 'channel',
          formatter: ((value) => {
            if (value === 'whatsapp-java') value = 'WhatsApp';
            else if (value === 'widget-java') value = 'Widget';
            else value = value.charAt(0).toUpperCase() + value.slice(1);
            return value;
          }),
        },
        'name',
        'phone',
        'agent_name',
        'identification_account',
        'tabulation',
        'evaluation',
        'actions',
      ],
      rooms: [],
      tempDownload: null,
      currentRoom: null,
      fetchTimeout: 0,
      fetched: true,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        startDate: moment().format('01/MM/YYYY'),
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      usage: [],
      currentPage: 1,
      totalPages: 1,
    };
  },
  created() {
    this.fetch();
  },
  beforeDestroy() {
    if (this.tempDownload) URL.revokeObjectURL(this.tempDownload);
  },
  methods: {
    fetch(page = 1) {
      this.fetching = true;
      this.$store
        .dispatch('fetchOmniRooms', {
          filter: {
            ...this.filters,
            createdAt: {
              gte: this.startDate,
              lt: this.endDate,
            },
          },
          page,
        })
        .then((response) => {
          this.rooms = response.data.data;
          this.totalPages = response.data.last_page;
        })
        .finally(() => {
          this.fetching = false;
        });
    },
    copyProtocol(protocol) {
      navigator.clipboard.writeText(protocol).then(() => {
        this.$toast.show({
          title: this.$t('live.copied'),
          type: 'success',
        });
      });
    },
    showMessages(room) {
      this.fetchRoom(room.id);
    },
    fetchRoom(roomId) {
      this.currentRoom = null;
      this.$refs['messages-modal'].toggle();
      this.$store.dispatch('fetchOmniRoom', roomId).then(
        (room) => {
          this.currentRoom = room;
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
    reload() {
      this.fetch(1);
    },
    prefetch(page) {
      clearTimeout(this.fetchTimeout);
      this.fetchTimeout = setTimeout(() => this.fetch(page), 10);
    },
    exportData(format) {
      this.fetching = true;

      // query=x&query=y&page=1

      const accounId = this.$store.state.account.id;
      const form = {};

      const params = [];

      if (form.id) {
        params.push(`id;eq;'${form.id}'`);
      } else {
        if (this.filters.channels.length > 0) {
          const channels = this.filters.channels.map((value) => value.toUpperCase());
          params.push(`channel_id;in;'${channels}'`);
        }

        if (this.filters.tabs.length > 0) {
          const tabs = this.filters.tabs.map((value) => value.toUpperCase());
          params.push(`tabs;in;'${tabs}'`);
        }

        if (this.filters.tags.length > 0) {
          const tags = this.filters.tags.map((value) => value.toUpperCase());
          params.push(`tag;in;'${tags}'`);
        }

        if (this.filters.protocol) {
          params.push(`protocol;eq;'${encodeURIComponent(this.filters.protocol)}'`);
        }

        if (this.startDate) {
          params.push(`creation_date;gte;'${new Date(this.startDate).toISOString()}'`);
        }

        if (this.endDate) {
          params.push(`creation_date;lte;'${new Date(this.endDate).toISOString()}'`);
        }

        params.push(`account_id;eq;'${accounId}'`);
      }

      const orderBy = 'orderBy=creation_date;desc';

      webApi.post(`/omni/reports/generate?query[]=${params.join('&query[]=')}&format=${format}&${orderBy}`, {
        from: this.filters.gte,
        to: this.filters.lte,
        format,
      }).then((response) => {
        console.log('Export');
        console.log(response);
        this.fetching = false;
        this.$router.push('/chat/reports/exports');
      }, (error) => {
        this.fetching = false;
        this.content = error;
        this.$toast.show({
          title: 'Erro',
          content: 'Erro interno, por favor tente novamente mais tarde ou contact o suporte para ajuda.',
          type: 'error',
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.protocol-cell {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: text-bottom;
}

a {
  color: #343a40;
}
</style>
